body {
  background-color: rgb(18, 32, 32);
  color: white;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-logo {
  height: 20vmin;
  pointer-events: none;
  border-radius: 25%;
}

.app-header {
  padding-top: 20vmin;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

.resource-list {
  display: flex;
}